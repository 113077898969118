<template>
  <div class="home-wrapper">
    <Content :bannerList="bannerList" />
  </div>
</template>

<script>
import Vue from 'vue';
import Content from '@/pages/content';
import { Carousel, CarouselItem, Image } from 'element-ui';
import ApiService from '@/api/apiService';
import API from '@/api';
import { LanguageEnum, Language } from '@/utils/constants';
Vue.component(Carousel.name, Carousel);
Vue.component(CarouselItem.name, CarouselItem);
Vue.component(Image.name, Image);

export default {
  name: 'home',
  components: { Content },
  props: {
    activeIndex: {
      type: String,
      default: '0'
    }
  },
  mounted() {},
  beforeRouteEnter: (to, from, next) => {
    next(vm => {
      // 通过 `vm` 访问组件实例
      vm.getBannerList();
    });
  },
  computed: {},
  watch: {
    '$store.state.isChinese'() {
      this.getBannerList();
    }
  },
  data() {
    return {
      bannerList: []
    };
  },
  methods: {
    async getBannerList() {
      const result = await ApiService.post(API.homePageList, {
        language: LanguageEnum[this.$store.state.isChinese ? Language.CN : Language.EN],
        businessType: 1
      });
      this.bannerList = result?.data?.desc || [];
    }
  }
};
</script>

<style lang="less" scoped>
.home-wrapper {
  margin: 10px 0 36px;

  .news {
    margin: 0 0px 2.8vw;
    header {
      margin: 1.4vw 0px;
      text-align: left;
      .button {
        padding: 0.277vw 0.7vw;
        margin-right: 6px;
        cursor: pointer;
        font-size: 1vw;
        background: #fff;
        color: #606266;
        border: 1px solid #dcdfe6;
        border-radius: 4px;
        &:hover {
          background-color: unset;
          color: #1a2043;
          border: 1px solid #1a2043;
        }
        &.active {
          background-color: unset;
          color: #1a2043;
          border: 1px solid #1a2043;
        }
      }
    }
  }

  .title {
    color: #515151;
    font-size: 1vw;
    text-align: left;
    margin: 1.4vw 0 4.3vw;
  }
  /deep/.carousel-content {
    margin-bottom: 2.2vw;
    .el-image {
      border-radius: 10px;
    }
    .el-carousel__container {
      height: 30vw;
    }
    .el-carousel__item {
      // background-color: #99a9bf;
      border-radius: 10px;
    }
  }
  .zuopin-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .item {
      width: 49%;
      .el-image {
        border-radius: 8px;
      }
    }
  }
  .news-content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    .item {
      width: 24%;
      margin-right: 1%;
      .el-image {
        border-radius: 8px;
      }
    }
  }
}
</style>
